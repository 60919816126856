import { Box } from '@mui/material';
import { Oval as Gridtimer } from 'react-loader-spinner';

export default function Smallloader() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '50px',
        width: '50px',
      }}
    >
      <Gridtimer
        color="#00C4D2"
        height="100%"
        width="100%"
        secondaryColor="#F8ECEA"
      />
    </Box>
  );
}
