import React, { useEffect, useState } from 'react';
import { useAppContext } from '../context/State';
import { Container, Box, Typography, Grid } from '@mui/material';
import DataListBox from '../components/DataListBox';
import axios from 'axios';
import Listloader from '../components/loaders/ListLoader';
import DealInformationBox from '../components/DealInformationBox';
import DealInvestBox from '../components/DealInvestBox';
import ErrorBlock from '../components/ErrorBlock';
import { returnNumberOrNull } from '../helpers/returnNumberOrNull';
import { useNavigate } from 'react-router-dom';
import { Invest, Redeem, Swap, Stake, Unstake } from '@valha/valha.js';
import { config } from '../helpers/authorizationHeader';
import { useNetwork, useSwitchNetwork } from 'wagmi';

export default function Dealpage() {
  const navigate = useNavigate();
  const { address, contractSigner } = useAppContext();
  const [loading, setLoading] = useState(true);
  const [interactionLoading, setInteractionLoading] = useState(false);
  const [positionLoading, setPositionLoading] = useState(false);
  const [error, setError] = useState('');
  const [dealAddress, setDealAddress] = useState('');
  const [dealChain, setDealChain] = useState('');
  const [poolAnalytics, setPoolAnalytics] = useState({
    liquidity: 0,
    outstanding_loans: 0,
    capacity: 0,
    tvl: 0,
    activity_apy: 0,
    rewards_apy: 0,
    total_apy: 0,
  });
  const [deal, setDeal] = useState({
    name: '',
    debate_link: '',
    dataroom_link: '',
    type: '',
    region: '',
    protocol: {
      image: '',
      name: '',
    },
    originator: {
      name: '',
      biography: '',
      link: '',
    },
  });
  const [position, setPosition] = useState(0);
  const [claimableRewards, setClaimableRewards] = useState(0);
  const [noClaimable, setNoClaimable] = useState(false);
  const [inputValue, setInputValue] = useState<string | number>('');
  const [fromToken, setFromToken] = useState('');
  const [toToken, setToToken] = useState('');
  const [amountToSwap, setAmountToSwap] = useState<string | number>('');
  const { chain } = useNetwork();
  const { chains, switchNetwork } = useSwitchNetwork();

  const boxHeight = 700;
  const boxMB = 10;

  useEffect(() => {
    async function fetchData(chain: string, poolAddress: string) {
      const url = process.env.REACT_APP_URL;
      const { data } = await axios.get(
        `${url}/v0/${chain}/pools/` + poolAddress,
        config
      );
      console.log(data);
      if (data.data) {
        console.log(data.data);
        setDeal(data.data);
        setPoolAnalytics(data.data.pool_analytics);
        setLoading(false);
      }
    }

    const dealChain = window.location.pathname.split('/')[2];
    const poolAddress = window.location.pathname.split('/')[3];
    if (chain && dealChain.toLowerCase() !== chain?.name.toLowerCase()) {
      console.log(chain?.name);
      let id = 1;
      if (dealChain === 'bsc') {
        id = 56;
      } else if (dealChain === 'polygon') {
        id = 137;
      } else if (dealChain === 'arbitrum') {
        id = 42161;
      } else if (dealChain === 'optimism') {
        id = 10;
      }
      switchNetwork?.(id);
    }
    setDealAddress(poolAddress);
    setDealChain(dealChain);
    fetchData(dealChain, poolAddress);
  }, []);

  useEffect(() => {
    async function fetchPosition(
      chain: string,
      userAddress: string,
      poolAddress: string
    ) {
      const url = process.env.REACT_APP_URL;
      const { data } = await axios.get(
        `${url}/v0/${chain}/positions/${userAddress}/pools/${poolAddress}`,
        config
      );
      if (data.data) {
        const result = data.data;
        setPosition(result.total_balance);
      } else if (data.err) {
        setError(data.err);
      }
    }

    async function fetchClaimableRewards(
      chain: string,
      userAddress: string,
      poolAddress: string
    ) {
      const url = process.env.REACT_APP_URL;
      const { data } = await axios.get(
        `${url}/v0/${chain}/pools/${poolAddress}/user/${userAddress}/rewards`,
        config
      );

      if (data.data) {
        const rewardsResult = data.data;
        if (rewardsResult.rewards_token.length < 12) {
          setNoClaimable(true);
        }
        setClaimableRewards(rewardsResult.rewards);
      } else if (data.err) {
        setError(data.err);
      }
    }

    if (address) {
      setPositionLoading(true);
      const dealChain = window.location.pathname.split('/')[2];
      const poolAddress = window.location.pathname.split('/')[3];
      fetchPosition(dealChain, address, poolAddress);
      fetchClaimableRewards(dealChain, address, poolAddress);
      setPositionLoading(false);
    }
  }, [address]);

  const onFromInputValue = (input: string) => {
    setFromToken(input);
  };

  const onToInputValue = (input: string) => {
    setToToken(input);
  };

  const onSwapInputValue = (input: string) => {
    setAmountToSwap(returnNumberOrNull(input));
  };

  const onInputValue = (input: string) => {
    setInputValue(returnNumberOrNull(input));
  };

  const onInvestClick = async () => {
    try {
      setInteractionLoading(true);
      const provider = contractSigner?.provider;
      await Invest(
        process.env.REACT_APP_API_KEY as string,
        provider,
        dealChain,
        dealAddress,
        inputValue,
        'v0'
      );
      setInteractionLoading(false);
    } catch (err) {
      setInteractionLoading(false);
      console.log(err);
    }
  };

  const onRedeemClick = async () => {
    try {
      setInteractionLoading(true);
      const provider = contractSigner?.provider;
      await Redeem(
        process.env.REACT_APP_API_KEY as string,
        provider,
        dealChain,
        dealAddress,
        inputValue,
        'v0'
      );
      setInteractionLoading(false);
    } catch (err) {
      setInteractionLoading(false);
      console.log(err);
    }
  };

  const onStakeClick = async () => {
    try {
      setInteractionLoading(true);
      const provider = contractSigner?.provider;
      await Stake(
        process.env.REACT_APP_API_KEY as string,
        provider,
        dealChain,
        dealAddress,
        inputValue,
        'v0'
      );
      setInteractionLoading(false);
    } catch (err) {
      setInteractionLoading(false);
      console.log(err);
    }
  };

  const onUnstakeClick = async () => {
    try {
      setInteractionLoading(true);
      const provider = contractSigner?.provider;
      await Unstake(
        process.env.REACT_APP_API_KEY as string,
        provider,
        dealChain,
        dealAddress,
        inputValue,
        'v0'
      );
      setInteractionLoading(false);
    } catch (err) {
      setInteractionLoading(false);
      console.log(err);
    }
  };
  //   try {
  //     const signer = contractSigner?.provider.getSigner();
  //     const Dispatcher = new ethers.Contract(
  //       String(process.env.REACT_APP_DISPATCHER_ADDRESS),
  //       DispatcherABI.abi,
  //       signer
  //     );
  //     const decimalsBN = await Dispatcher.checkDecimals(
  //       'Truefi',
  //       parseInt(dealId)
  //     );
  //     const decimals = parseInt(decimalsBN.toString());
  //     const amount = new BN(String(inputValue)).mul(
  //       new BN(String(10 ** decimals))
  //     );
  //     // const gasMax0 = new BN(String(0.001 * 10 ** 18));
  //     // const gasMax1 = new BN(String(0.001 * 10 ** 18));
  //     const USDCToken = '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48';
  //     const USDC = new ethers.Contract(USDCToken, ERC20ABI, signer);
  //     await USDC.approve(
  //       String(process.env.REACT_APP_DISPATCHER_ADDRESS),
  //       amount.toString()
  //       // {
  //       //   gasLimit: gasMax0.toString(),
  //       // }
  //     );
  //     const tx = await Dispatcher.deposit(
  //       'Truefi',
  //       dealId,
  //       amount.toString()
  //       // {
  //       //   gasLimit: gasMax1.toString(),
  //       // }
  //     );
  //     await tx.wait();
  //     console.log('click invest');
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // const onRedeemClick = async () => {
  //   try {
  //     const signer = contractSigner?.provider.getSigner();
  //     const Dispatcher = new ethers.Contract(
  //       String(process.env.REACT_APP_DISPATCHER_ADDRESS),
  //       DispatcherABI.abi,
  //       signer
  //     );
  //     const decimalsBN = await Dispatcher.checkDecimals(
  //       'Truefi',
  //       parseInt(dealId)
  //     );
  //     const decimals = parseInt(decimalsBN.toString());
  //     const amount = new BN(String(inputValue)).mul(
  //       new BN(String(10 ** decimals))
  //     );
  //     // const gasMax0 = new BN(String(0.001 * 10 ** 18));
  //     // const gasMax1 = new BN(String(0.001 * 10 ** 18));
  //     const tokenAddress = dealAddress;
  //     const TOKEN = new ethers.Contract(tokenAddress, ERC20ABI, signer);
  //     await TOKEN.approve(
  //       String(process.env.REACT_APP_DISPATCHER_ADDRESS),
  //       amount.toString()
  //       // {
  //       //   gasLimit: gasMax0.toString(),
  //       // }
  //     );
  //     const tx = await Dispatcher.redeem(
  //       'Truefi',
  //       dealId,
  //       amount.toString()
  //       // {
  //       //   gasLimit: gasMax1.toString(),
  //       // }
  //     );
  //     await tx.wait();
  //     console.log('click redeeeem');
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const onSwapClick = async () => {
    try {
      setInteractionLoading(true);
      const provider = contractSigner?.provider;
      await Swap(
        process.env.REACT_APP_API_KEY as string,
        provider,
        dealChain,
        fromToken ? fromToken : '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
        toToken,
        amountToSwap,
        'v0'
      );
      setInteractionLoading(false);
    } catch (err) {
      setInteractionLoading(false);
      console.log(err);
    }
  };

  return (
    <Container className="main-container" maxWidth="xl">
      {loading && Object.keys(deal).length !== 0 ? (
        <Listloader />
      ) : error ? (
        <Box sx={{ mt: 25 }}>
          <ErrorBlock message={error} />
        </Box>
      ) : (
        <Box
          sx={{
            height: '25000',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            mt: 2,
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mr: 1,
              }}
            >
              <img
                src={deal.protocol.image}
                style={{ width: 50, borderRadius: 50 }}
                alt="poolegy-logo"
              />
            </Box>
            <Typography
              sx={{ fontSize: 26, fontWeight: 'bold', letterSpacing: 1 }}
            >
              {deal.name ? deal.name : 'test'}
            </Typography>
          </Box>
          <DataListBox
            tvl={poolAnalytics.tvl}
            loans={poolAnalytics.outstanding_loans}
            capacity={poolAnalytics.capacity}
            liquidity={poolAnalytics.liquidity}
            apy={poolAnalytics.total_apy}
          />
          <Grid
            container
            spacing={2}
            mt={2}
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <DealInformationBox
              height={boxHeight}
              name={deal ? deal.name : 'test'}
              region={deal.region}
              sector={deal.type || 'DeFi Pool'}
              originator_name={
                deal.originator ? deal.originator.name : 'unknown'
              }
              originator_biography={
                deal.originator ? deal.originator.biography : 'unknown'
              }
              navigate={navigate}
              dealAddress={dealAddress}
              chain={dealChain}
              mb={boxMB}
            />
            <DealInvestBox
              height={boxHeight}
              mb={boxMB}
              address={address}
              dealAddress={dealAddress}
              position={position}
              claimableRewards={claimableRewards}
              noClaimable={noClaimable}
              inputValue={inputValue}
              fromToken={fromToken}
              toToken={toToken}
              amountToSwap={amountToSwap}
              positionloading={positionLoading}
              loading={interactionLoading}
              onInputValue={onInputValue}
              onRedeemClick={onRedeemClick}
              onInvestClick={onInvestClick}
              onStakeClick={onStakeClick}
              onUnstakeClick={onUnstakeClick}
              onSwapClick={onSwapClick}
              onToInputValue={onToInputValue}
              onFromInputValue={onFromInputValue}
              onSwapInputValue={onSwapInputValue}
            />
          </Grid>
        </Box>
      )}
    </Container>
  );
}
