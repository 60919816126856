import { Grid, Typography, Box } from '@mui/material';
import { Item } from './styledElements/Item';
import { numberWithCommas } from '../helpers/numberWithCommas';
import StatTitleBigBox from './StatTitleBigBox';

export default function DataListBox(props: any) {
  return (
    <Grid
      container
      spacing={2}
      sx={{ justifyContent: 'center', alignItems: 'center', mt: 1 }}
    >
      <StatTitleBigBox
        title={'TVL'}
        stat={'$' + numberWithCommas(parseInt(String(props.tvl)))}
      />
      <StatTitleBigBox
        title={'Outstanding Loans'}
        stat={'$' + numberWithCommas(parseInt(String(props.loans)))}
      />
      <StatTitleBigBox
        title={'Remaining Capacity'}
        stat={
          props.capacity === 999999999999
            ? 'No fixed limit.'
            : '$' + numberWithCommas(parseInt(String(props.capacity)))
        }
      />
      <StatTitleBigBox
        title={'Liquidity'}
        stat={'$' + numberWithCommas(parseInt(String(props.liquidity)))}
      />
      <StatTitleBigBox
        title={'Expected APY'}
        stat={
          (props.apy
            ? parseFloat(String(props.apy)).toFixed(4)
            : parseFloat(String(0)).toFixed(1)) + '%'
        }
      />
    </Grid>
  );
}
