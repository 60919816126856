import React, { useEffect, useState, useContext } from 'react';
import { Container, Box, Typography } from '@mui/material';
import '../index.css';
import ErrorBlock from '../components/ErrorBlock';

export default function Errorpage(props: any) {
  return (
    <Box sx={{ mt: 25 }}>
      <ErrorBlock />;
    </Box>
  );
}
