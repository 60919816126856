import React, { useEffect, useState, useContext } from 'react';
import { Container, Box, Typography } from '@mui/material';
import '../index.css';

export default function SetPassword() {
  const [key, setKey] = useState('');

  useEffect(() => {
    try {
      const id = window.location.search.split('=')[1];
      if (!id) throw new Error('No key was found.');
      console.log(id);
      setKey(id);
    } catch (err) {
      console.log(err);
    }
  }, []);
  return (
    <Container className="main-container" maxWidth="xl">
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          mt: 10,
        }}
      >
        <Typography>Set your password</Typography>
      </Box>
    </Container>
  );
}
