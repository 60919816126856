import React, { useEffect, useState } from 'react';
// import { useAppContext } from '../context/stateContext';
import { Container, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PoolList from '../components/PoolList';
import '../index.css';
import axios from 'axios';
import ErrorBlock from '../components/ErrorBlock';
import { config } from '../helpers/authorizationHeader';

export default function Homepage() {
  const [error, setError] = useState('');
  const [pools, setPools] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      const url = process.env.REACT_APP_URL;
      // const { data } = await axios.get(`${url}/v0/ethereum/pools`, config);
      const result = await axios.get(`${url}/v0/chains/pools`, config);
      // const resultEthereum = await axios.get(`${url}/v0/chains/pools`, config);
      // const resultBSC = await axios.get(`${url}/v0/bsc/pools`, config);
      // const resultPOLYGON = await axios.get(`${url}/v0/polygon/pools`, config);

      const data = result.data.data;
      // const dataETH = resultEthereum.data.data;
      // const dataBSC = resultBSC.data.data;
      // const dataPolygon = resultPOLYGON.data.data;
      // console.log(dataPolygon);
      // const result = dataETH.concat(dataBSC.concat(dataPolygon));
      console.log('----------');
      console.log(result);
      if (data) {
        console.log(result);
        setPools(data);
      } else if (data.err) {
        setError(data.err);
      }
    }

    fetchData();
  }, []);

  return (
    <Container className="main-container" maxWidth="xl">
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          mt: 3,
        }}
      >
        {error ? (
          <Box sx={{ mt: 25 }}>
            <ErrorBlock message={error} />
          </Box>
        ) : (
          <>
            <Typography
              sx={{ mb: 5, fontSize: 28, fontWeight: 'bold', letterSpacing: 1 }}
            >
              Available Investments
            </Typography>
            <PoolList pools={pools} navigate={navigate} />
          </>
        )}
      </Box>
    </Container>
  );
}
