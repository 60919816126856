import { Grid, Typography, Box, Button } from '@mui/material';
import { Item } from './styledElements/Item';

export default function DealInformationBox(props: any) {
  return (
    <Grid item xs={7}>
      <Typography sx={{ ml: 1, fontWeight: 'bold', fontSize: 26 }}>
        Deal
      </Typography>
      <Item sx={{ height: props.height, mb: props.mb }}>
        <Box
          sx={{
            mt: 5,
            borderBottom: 1,
            padding: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography sx={{ width: '20%' }}>Sector</Typography>
          <Typography sx={{ fontSize: 18, fontWeight: 'bold', width: '80%' }}>
            {props.sector}
          </Typography>
        </Box>
        {/* <Box
          sx={{
            borderBottom: 1,
            padding: 2,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography sx={{ width: '20%' }}>Region</Typography>
          <Typography sx={{ fontSize: 14, width: '80%' }}>
            {props.region}
          </Typography>
        </Box> */}
        {/* <Box
          sx={{
            borderBottom: 1,
            padding: 2,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography sx={{ width: '20%' }}>Originator</Typography>
          <Box sx={{ width: '80%' }}>
            <Typography sx={{ fontSize: 14 }}>
              {props.originator_name}
            </Typography>
            <Typography sx={{ fontSize: 14 }}>
              {props.originator_biography}
            </Typography>
          </Box>
        </Box> */}
        <Box
          sx={{
            padding: 3,
            mt: 5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            // justifyContent: 'space-between',
          }}
        >
          {/* <Box>
            <Typography>Risks Factor</Typography>
            <Button
              variant="contained"
              sx={{
                borderRadius: '5px',
                mt: 1,
                width: 150,
                height: 30,
                backgroundColor: '#009AE4',
                fontSize: 14,
                fontWeight: 'bold',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: '#F5F5F5',
                  color: '#FF968D',
                },
              }}
              onClick={() =>
                props.navigate(
                  `/dealpage/${props.chain}/${props.dealAddress}/risk`
                )
              }
            >
              Check Risks
            </Button>
          </Box> */}
          <Box sx={{ mr: 2 }}>
            <Typography>Listing Information</Typography>
            <Button
              variant="contained"
              sx={{
                borderRadius: '5px',
                mt: 1,
                width: 150,
                height: 30,
                backgroundColor: '#009AE4',
                fontSize: 14,
                fontWeight: 'bold',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: '#F5F5F5',
                  color: '#FF968D',
                },
              }}
            >
              See Debate
            </Button>
          </Box>
          <Box>
            <Typography> Dataroom</Typography>
            <Button
              variant="contained"
              sx={{
                borderRadius: '5px',
                mt: 1,
                width: 150,
                height: 30,
                backgroundColor: '#009AE4',
                fontSize: 14,
                fontWeight: 'bold',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: '#F5F5F5',
                  color: '#FF968D',
                },
              }}
            >
              Get Docs
            </Button>
          </Box>
        </Box>
      </Item>
    </Grid>
  );
}
