import Header from './components/Header';
import Homepage from './views/Homepage';
import Dealpage from './views/Dealpage';
import APIDoc from './views/APIDoc';
import Errorpage from './views/Errorpage';
import Riskpage from './views/Riskpage';
import Walletpage from './views/Walletpage';
import Footer from './components/Footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ContextProvider from './context/State';
import '@rainbow-me/rainbowkit/styles.css';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';
import {
  getDefaultWallets,
  RainbowKitProvider,
  lightTheme,
  connectorsForWallets,
} from '@rainbow-me/rainbowkit';
import { chain, configureChains, createClient, WagmiConfig } from 'wagmi';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';
import { safeWallet } from './helpers/wallets/safeWallet';
import '@rainbow-me/rainbowkit/styles.css';
import SetPassword from './views/SetPassword';
import AdminRequest from './views/AdminRequest';
import Widget from './views/Widget';
import RouterView from './views/Router';
import Connector from './views/Connector';

function App() {
  const tenderlyChain: any = {
    id: 4242,
    name: 'STAGING - Fork',
    network: 'Tenderly',
    iconUrl: 'https://i.imgur.com/uHo1KVc.png',
    iconBackground: '#fff',
    nativeCurrency: {
      decimals: 18,
      name: 'ethereum',
      symbol: 'eth',
    },
    rpcUrls: {
      default: `https://rpc.tenderly.co/fork/${process.env.REACT_APP_FORK_ID}`,
    },
    blockExplorers: {
      default: {
        name: 'Tenderly Explorer',
        url: '',
      },
    },
    testnet: false,
  };

  const bscChain: any = {
    id: 56,
    name: 'BSC',
    network: 'BSC',
    iconUrl:
      'https://public.bnbstatic.com/20190405/eb2349c3-b2f8-4a93-a286-8f86a62ea9d8.png',
    iconBackground: '#fff',
    nativeCurrency: {
      decimals: 18,
      name: 'bnb',
      symbol: 'bnb',
    },
    rpcUrls: {
      default: `https://bsc-dataseed.binance.org/`,
    },
    blockExplorers: {
      default: {
        name: 'Bnbscan',
        url: 'https://bscscan.com',
      },
    },
    testnet: false,
  };

  const chainsConfiguration =
    process.env.REACT_APP_VERSION === 'PROD'
      ? [chain.mainnet, chain.polygon, chain.arbitrum, chain.optimism, bscChain]
      : [
          chain.mainnet,
          chain.polygon,
          chain.arbitrum,
          chain.optimism,
          bscChain,
          tenderlyChain,
        ];

  const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
  const { chains, provider } = configureChains(chainsConfiguration, [
    alchemyProvider({
      apiKey: alchemyKey,
      priority: 0,
    }),
    publicProvider({ priority: 1 }),
    jsonRpcProvider({
      rpc: (chain: any) => ({ http: chain.rpcUrls.default }),
    }),
  ]);

  const appName = 'Valha';

  const { wallets } = getDefaultWallets({
    appName: appName,
    chains,
  });
  wallets[0].wallets.push(safeWallet({ chains }));

  const connectors = connectorsForWallets(wallets);
  const wagmiClient = createClient({
    connectors: connectors,
    provider,
  });

  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider
        initialChain={chain.mainnet}
        chains={chains}
        theme={lightTheme({
          accentColor: '#01D2B2',
          accentColorForeground: 'white',
          borderRadius: 'medium',
          overlayBlur: 'small',
        })}
      >
        <ContextProvider>
          <Router>
            <Header />
            <Routes>
              <Route path="/error" element={<Errorpage />} />
              <Route path="/dealpage/:chain/:id" element={<Dealpage />} />
              <Route path="/dealpage/:chain/:id/risk" element={<Riskpage />} />
              <Route path="/api-documentation" element={<APIDoc />} />
              <Route path="/wallet" element={<Walletpage />} />
              <Route path="/set-password" element={<SetPassword />} />
              <Route path="/admin-test/analytics" element={<AdminRequest />} />
              <Route path="/widget" element={<Widget />} />
              <Route path="/router" element={<RouterView />} />
              <Route path="/list" element={<Homepage />} />
              <Route path="/add-connector" element={<Connector />} />
              <Route path="*" element={<Homepage />} />
            </Routes>
            <Footer />
          </Router>
        </ContextProvider>
      </RainbowKitProvider>
    </WagmiConfig>
  );
}

export default App;
