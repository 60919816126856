import { useConnect } from 'wagmi';
import { useDisconnect } from 'wagmi';
import { useAccount } from 'wagmi';
import { useEffect } from 'react';

const AUTOCONNECTED_CONNECTOR_IDS = [
  'safe',
  'metaMask',
  'walletConnect',
  'brave',
];

function useAutoConnect() {
  const { connect, connectors } = useConnect();
  const { isConnecting, isConnected } = useAccount();
  const { disconnect } = useDisconnect();

  useEffect(() => {
    if (isConnected || isConnecting) {
      disconnect();
    }

    AUTOCONNECTED_CONNECTOR_IDS.forEach((connector) => {
      const connectorInstance = connectors.find(
        (c) => c.id === connector && c.ready
      );

      if (connectorInstance) {
        connect({ connector: connectorInstance });
      }
    });
  }, [connect, connectors]);
}

export { useAutoConnect };
