import { Typography, Container, Box } from '@mui/material';

export default function ErrorBlock(props: any) {
  return (
    <Container className="main-container" maxWidth="xl">
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Typography
          sx={{
            width: 900,
            fontWeight: 'bold',
            fontSize: '25px',
            justifyContent: 'center',
            textJustify: 'auto',
          }}
        >
          We are sorry but an <span style={{ color: '#FE8F96' }}> ERROR </span>{' '}
          occured. Please try again by reloading this site or just{' '}
          <span style={{ color: '#01BFE0' }}>contact us</span>.<br></br>
          <br></br>
          The error is: <br></br>
          {props.message ? props.message : 'unknown'}
        </Typography>
      </Box>
    </Container>
  );
}
