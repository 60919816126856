/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import Container from '../../Container';
import General from './General';

const FormInfo = (props: any) => {
  return (
    <Container>
      <Box>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom fontWeight={700}>
              {props.title}
            </Typography>
            <Typography variant={'subtitle2'} color={'text.secondary'}>
              {props.description}
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <General />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default FormInfo;
