export function returnNumberOrNull(input) {
  if (input === '') {
    return '';
  }
  if (input[input.length - 1] === '.' || input[input.length - 1] === '0') {
    return input;
  }
  const value = parseFloat(input);
  if (!isNaN(value)) {
    return value;
  }
  return '';
}
