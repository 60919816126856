import { Container, Box, Typography } from '@mui/material';
import '../index.css';

export default function Widget() {
  return (
    <Container className="main-container" maxWidth="xl">
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          mt: 1,
        }}
      >
        <Typography
          sx={{
            width: 900,
            fontWeight: 'bold',
            fontSize: '25px',
            justifyContent: 'center',
            textJustify: 'auto',
            mb: 1,
          }}
        >
          Valha Widget
        </Typography>
        <iframe
          title="valha-widget"
          src="https://valha-widget.vercel.app/?apiKey=abc&authorizedChains=ethereum&authorizedProtocols=angle,stargate,clearpool"
          frameBorder="0"
          width="700px"
          height="620px"
        />
        {/* </iframe> */}
      </Box>
    </Container>
  );
}
