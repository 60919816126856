import React, { useEffect, useState, useContext } from 'react';
import { Container, Box, Typography, Icon } from '@mui/material';
import '../index.css';
import RiskBlock from '../components/RiskBlock';
import axios from 'axios';
import Listloader from '../components/loaders/ListLoader';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate } from 'react-router-dom';
import { config } from '../helpers/authorizationHeader';

export default function Errorpage(props: any) {
  const navigate = useNavigate();
  const [risks, setRisks] = useState({});
  const [loading, setLoading] = useState(true);
  const [dealAddress, setDealAddress] = useState('');

  useEffect(() => {
    async function fetchData(id: string) {
      const url = process.env.REACT_APP_URL;
      const { data } = await axios.get(
        `${url}/v0/ethereum/pool-risks/` + id,
        config
      );
      console.log(data);
      if (data.data) {
        setRisks(data.data[0]);
        setLoading(false);
      }
    }
    const id = window.location.pathname.split('/')[2];
    setDealAddress(id);
    fetchData(id);
  }, []);

  return (
    <Container>
      {loading ? (
        <Listloader />
      ) : (
        <Box sx={{ mt: 5 }}>
          <Box>
            <ArrowBackIosNewIcon
              fontSize="small"
              sx={{ cursor: 'pointer' }}
              onClick={() => navigate(`/dealpage/${dealAddress}`)}
            />
            <Typography
              sx={{
                display: 'flex',
                justifyContent: 'center',
                fontSize: 28,
                fontWeight: 'bold',
                mb: 5,
              }}
            >
              Risks Factors
            </Typography>
          </Box>
          <RiskBlock
            lossRisk={true}
            apyRisk={true}
            contractRisk={true}
            liquidityRisk={true}
            investmentRisk={'debt'}
          />
        </Box>
      )}
    </Container>
  );
}
