import { Typography, Box } from '@mui/material';

export default function StatTitleBox(props: any) {
  return (
    <Box sx={{ width: '20%' }}>
      <Typography
        color="#FFFFFF"
        sx={{
          width: '100%',
          fontSize: 9,
          color: '#F8EDEB',
          fontWeight: 'semi-bold',
        }}
      >
        {props.title}
      </Typography>
      <Typography
        color="#FFFFFF"
        sx={{
          width: '100%',
          fontSize: { xs: 10, sm: 14, md: 20 },
          color: 'white',
        }}
      >
        {props.stat}
      </Typography>
    </Box>
  );
}
