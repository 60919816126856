import React, { useEffect, useState } from 'react';
import { useAppContext } from '../context/State';
import {
  Container,
  Typography,
  Box,
  Button,
  Paper,
  InputBase,
} from '@mui/material';
import '../index.css';
import { ethers } from 'ethers';
import TOKENABI from '../abi/ERC20.json';
import ROUTERABI from '../abi/Router.json';
import FACTORYABI from '../abi/Factory.json';

export default function RouterView() {
  const { address, contractSigner } = useAppContext();
  const [routerAddress, setRouterAddress] = useState(
    '0x54C02D843CE0B47083e1A5a171B4d4b5cf336790'
  );
  const [factoryAddress, setFactoryAddress] = useState(
    '0x41A916b5D57CC95c7ec63e8D11d0690A26695163'
  );

  const [erc20Address, setERC20Address] = useState<string | null>(null);
  const [spenderAddress, setSpenderAddress] = useState<string | null>(
    '0x54C02D843CE0B47083e1A5a171B4d4b5cf336790'
  );
  const [spendingAmount, setSpendingAmount] = useState<string | null>(null);

  const [protocol, setProtocol] = useState<string | null>(null);
  const [action, setAction] = useState<string | null>(null);
  const [interactionAmount, setInteractionAmount] = useState<string | null>(
    null
  );
  const [deadline, setDeadline] = useState<string | null>(null);
  const [id, setId] = useState<string | null>(null);
  const [addressId, setAddressId] = useState<string | null>(null);
  const [interactionAddress, setInteractionAddress] = useState<string | null>(
    null
  );
  const [token, setToken] = useState<string | null>(null);
  const [src, setSrc] = useState<string | null>(null);
  const [dst, setDst] = useState<string | null>(null);
  const [referral, setReferral] = useState<string | null>(null);

  const [addressToSweep, setAddressToSweep] = useState<string | null>(null);
  const [sweepDst, setSweepDst] = useState<string | null>(null);

  const [positionLoading, setPositionLoading] = useState(false);
  const [error, setError] = useState('');

  const [userAddressFactory, setUserAddressFactory] = useState<string | null>(
    null
  );

  const approveERC = async () => {
    if (erc20Address) {
      const provider = contractSigner?.provider;
      const signer = provider.getSigner();
      const TOKEN = new ethers.Contract(erc20Address, TOKENABI, signer);
      await TOKEN.approve(routerAddress, spendingAmount);
    }
  };

  const interact = async () => {
    const provider = contractSigner?.provider;
    const signer = provider.getSigner();
    const ROUTER = new ethers.Contract(routerAddress, ROUTERABI, signer);

    await ROUTER.route(
      protocol,
      action,
      interactionAmount,
      deadline,
      id,
      addressId,
      interactionAddress,
      token,
      src,
      dst,
      referral
    );
  };

  const sweep = async () => {
    const provider = contractSigner?.provider;
    const signer = provider.getSigner();
    const ROUTER = new ethers.Contract(routerAddress, ROUTERABI, signer);

    await ROUTER.sweep(addressToSweep, sweepDst);
  };

  const createRouter = async () => {
    const provider = contractSigner?.provider;
    const signer = provider.getSigner();
    const FACTORY = new ethers.Contract(routerAddress, FACTORYABI, signer);
    await FACTORY.createRouter(userAddressFactory);
  };

  return (
    <Container className="main-container" maxWidth="xl">
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          mt: 2,
        }}
      >
        <Typography
          sx={{
            width: 900,
            fontWeight: 'bold',
            fontSize: '20px',
            justifyContent: 'center',
            textJustify: 'auto',
            mb: 1,
          }}
        >
          Approve the router for a specific amount on an ERC20
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Paper
            elevation={2}
            component="form"
            sx={{
              p: '5px 5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: 1,
              width: 500,
              mb: 1,
            }}
          >
            <InputBase
              autoFocus={true}
              sx={{ ml: 1, flex: 1 }}
              placeholder="_ERC20Address"
              inputProps={{ 'aria-label': 'invest money' }}
              onChange={(e) => setERC20Address(e.target.value)}
              value={erc20Address}
            />
          </Paper>
          <Paper
            elevation={2}
            component="form"
            sx={{
              p: '5px 5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: 1,
              width: 500,
              mb: 1,
            }}
          >
            <InputBase
              autoFocus={false}
              sx={{ ml: 1, flex: 1 }}
              placeholder="_spender"
              inputProps={{ 'aria-label': 'invest money' }}
              onChange={(e) => setSpenderAddress(e.target.value)}
              value={spenderAddress}
            />
          </Paper>
          <Paper
            elevation={2}
            component="form"
            sx={{
              p: '5px 5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: 1,
              width: 500,
              mb: 1,
            }}
          >
            <InputBase
              autoFocus={false}
              sx={{ ml: 1, flex: 1 }}
              placeholder="_amount (10**18 or 10**6)"
              inputProps={{ 'aria-label': 'invest money' }}
              onChange={(e) => setSpendingAmount(e.target.value)}
              value={spendingAmount}
            />
          </Paper>
          <Button
            variant="contained"
            sx={{
              borderRadius: '25px',
              width: 200,
              height: 40,
              mt: 1,
              backgroundColor: '#FE859D',
              fontsize: 14,
              fontWeight: 'bold',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: '#F5F5F5',
                color: '#FE859D',
              },
            }}
            onClick={() => approveERC()}
          >
            Approve
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          mt: 5,
          mb: 5,
        }}
      >
        <Typography
          sx={{
            width: 900,
            fontWeight: 'bold',
            fontSize: '20px',
            justifyContent: 'center',
            textJustify: 'auto',
            mb: 1,
          }}
        >
          Interact with the onchain router!
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Paper
            elevation={2}
            component="form"
            sx={{
              p: '5px 5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: 1,
              width: 500,
              mb: 1,
            }}
          >
            <InputBase
              autoFocus={false}
              sx={{ ml: 1, flex: 1 }}
              placeholder="Router Address"
              inputProps={{ 'aria-label': 'invest money' }}
              onChange={(e) => setRouterAddress(e.target.value)}
              value={routerAddress}
            />
          </Paper>
          <Paper
            elevation={2}
            component="form"
            sx={{
              p: '5px 5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: 1,
              width: 500,
              mb: 1,
            }}
          >
            <InputBase
              autoFocus={false}
              sx={{ ml: 1, flex: 1 }}
              placeholder="_protocol"
              inputProps={{ 'aria-label': 'invest money' }}
              onChange={(e) => setProtocol(e.target.value)}
              value={protocol}
            />
          </Paper>
          <Paper
            elevation={2}
            component="form"
            sx={{
              p: '5px 5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: 1,
              width: 500,
              mb: 1,
            }}
          >
            <InputBase
              autoFocus={false}
              sx={{ ml: 1, flex: 1 }}
              placeholder="_action"
              inputProps={{ 'aria-label': 'invest money' }}
              onChange={(e) => setAction(e.target.value)}
              value={action}
            />
          </Paper>
          <Paper
            elevation={2}
            component="form"
            sx={{
              p: '5px 5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: 1,
              width: 500,
              mb: 1,
            }}
          >
            <InputBase
              autoFocus={false}
              sx={{ ml: 1, flex: 1 }}
              placeholder="_amount (10**18 or 10**6)"
              inputProps={{ 'aria-label': 'invest money' }}
              onChange={(e) => setInteractionAmount(e.target.value)}
              value={interactionAmount}
            />
          </Paper>
          <Paper
            elevation={2}
            component="form"
            sx={{
              p: '5px 5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: 1,
              width: 500,
              mb: 1,
            }}
          >
            <InputBase
              autoFocus={false}
              sx={{ ml: 1, flex: 1 }}
              placeholder="_deadline"
              inputProps={{ 'aria-label': 'invest money' }}
              onChange={(e) => setDeadline(e.target.value)}
              value={deadline}
            />
          </Paper>
          <Paper
            elevation={2}
            component="form"
            sx={{
              p: '5px 5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: 1,
              width: 500,
              mb: 1,
            }}
          >
            <InputBase
              autoFocus={false}
              sx={{ ml: 1, flex: 1 }}
              placeholder="_id (uint256)"
              inputProps={{ 'aria-label': 'invest money' }}
              onChange={(e) => setId(e.target.value)}
              value={id}
            />
          </Paper>
          <Paper
            elevation={2}
            component="form"
            sx={{
              p: '5px 5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: 1,
              width: 500,
              mb: 1,
            }}
          >
            <InputBase
              autoFocus={false}
              sx={{ ml: 1, flex: 1 }}
              placeholder="_addressId (address)"
              inputProps={{ 'aria-label': 'invest money' }}
              onChange={(e) => setAddressId(e.target.value)}
              value={addressId}
            />
          </Paper>
          <Paper
            elevation={2}
            component="form"
            sx={{
              p: '5px 5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: 1,
              width: 500,
              mb: 1,
            }}
          >
            <InputBase
              autoFocus={false}
              sx={{ ml: 1, flex: 1 }}
              placeholder="_interactionAddress"
              inputProps={{ 'aria-label': 'invest money' }}
              onChange={(e) => setInteractionAddress(e.target.value)}
              value={interactionAddress}
            />
          </Paper>
          <Paper
            elevation={2}
            component="form"
            sx={{
              p: '5px 5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: 1,
              width: 500,
              mb: 1,
            }}
          >
            <InputBase
              autoFocus={false}
              sx={{ ml: 1, flex: 1 }}
              placeholder="_token"
              inputProps={{ 'aria-label': 'invest money' }}
              onChange={(e) => setToken(e.target.value)}
              value={token}
            />
          </Paper>
          <Paper
            elevation={2}
            component="form"
            sx={{
              p: '5px 5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: 1,
              width: 500,
              mb: 1,
            }}
          >
            <InputBase
              autoFocus={false}
              sx={{ ml: 1, flex: 1 }}
              placeholder="_src"
              inputProps={{ 'aria-label': 'invest money' }}
              onChange={(e) => setSrc(e.target.value)}
              value={src}
            />
          </Paper>
          <Paper
            elevation={2}
            component="form"
            sx={{
              p: '5px 5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: 1,
              width: 500,
              mb: 1,
            }}
          >
            <InputBase
              autoFocus={false}
              sx={{ ml: 1, flex: 1 }}
              placeholder="_dst"
              inputProps={{ 'aria-label': 'invest money' }}
              onChange={(e) => setDst(e.target.value)}
              value={dst}
            />
          </Paper>
          <Paper
            elevation={2}
            component="form"
            sx={{
              p: '5px 5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: 1,
              width: 500,
              mb: 1,
            }}
          >
            <InputBase
              autoFocus={false}
              sx={{ ml: 1, flex: 1 }}
              placeholder="_referral"
              inputProps={{ 'aria-label': 'invest money' }}
              onChange={(e) => setReferral(e.target.value)}
              value={referral}
            />
          </Paper>
          <Button
            variant="contained"
            sx={{
              borderRadius: '25px',
              width: 200,
              height: 40,
              mt: 1,
              backgroundColor: '#FE859D',
              fontsize: 14,
              fontWeight: 'bold',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: '#F5F5F5',
                color: '#FE859D',
              },
            }}
            onClick={() => interact()}
          >
            Do It
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          mt: 5,
          mb: 5,
        }}
      >
        <Typography
          sx={{
            width: 900,
            fontWeight: 'bold',
            fontSize: '20px',
            justifyContent: 'center',
            textJustify: 'auto',
            mb: 1,
          }}
        >
          Sweep the balance of the ERC20
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Paper
            elevation={2}
            component="form"
            sx={{
              p: '5px 5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: 1,
              width: 500,
              mb: 1,
            }}
          >
            <InputBase
              autoFocus={false}
              sx={{ ml: 1, flex: 1 }}
              placeholder="_ERC20Address"
              inputProps={{ 'aria-label': 'invest money' }}
              onChange={(e) => setAddressToSweep(e.target.value)}
              value={addressToSweep}
            />
          </Paper>
          <Paper
            elevation={2}
            component="form"
            sx={{
              p: '5px 5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: 1,
              width: 500,
              mb: 1,
            }}
          >
            <InputBase
              autoFocus={false}
              sx={{ ml: 1, flex: 1 }}
              placeholder="Destination Address"
              inputProps={{ 'aria-label': 'invest money' }}
              onChange={(e) => setSweepDst(e.target.value)}
              value={sweepDst}
            />
          </Paper>
          <Button
            variant="contained"
            sx={{
              borderRadius: '25px',
              width: 200,
              height: 40,
              mt: 1,
              backgroundColor: '#FE859D',
              fontsize: 14,
              fontWeight: 'bold',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: '#F5F5F5',
                color: '#FE859D',
              },
            }}
            onClick={() => sweep()}
          >
            Sweep
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          mt: 2,
          mb: 10,
        }}
      >
        <Typography
          sx={{
            width: 900,
            fontWeight: 'bold',
            fontSize: '20px',
            justifyContent: 'center',
            textJustify: 'auto',
            mb: 1,
          }}
        >
          Create New Router From Factory
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Paper
            elevation={2}
            component="form"
            sx={{
              p: '5px 5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: 1,
              width: 500,
              mb: 1,
            }}
          >
            <InputBase
              autoFocus={false}
              sx={{ ml: 1, flex: 1 }}
              placeholder="_ERC20Address"
              inputProps={{ 'aria-label': 'invest money' }}
              onChange={(e) => setUserAddressFactory(e.target.value)}
              value={userAddressFactory}
            />
          </Paper>
          <Button
            variant="contained"
            sx={{
              borderRadius: '25px',
              width: 200,
              height: 40,
              mt: 1,
              backgroundColor: '#FE859D',
              fontsize: 14,
              fontWeight: 'bold',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: '#F5F5F5',
                color: '#FE859D',
              },
            }}
            onClick={() => createRouter()}
          >
            Create Router
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
