import { Container, Box, Typography } from '@mui/material';
import '../index.css';

export default function APIDoc() {
  return (
    <Container className="main-container" maxWidth="xl">
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          mt: 30,
        }}
      >
        <Typography
          sx={{
            width: 900,
            fontWeight: 'bold',
            fontSize: '25px',
            justifyContent: 'center',
            textJustify: 'auto',
          }}
        >
          The{' '}
          <span style={{ textDecoration: 'underline' }}>
            {' '}
            API documentation{' '}
          </span>{' '}
          will soon be available. <br /> <br /> Our focus is to build the best
          <span style={{ color: '#009DE8' }}> A</span>
          <span style={{ color: '#ECB1A4' }}>P</span>
          <span style={{ color: '#FE8F96' }}>I</span> possible to let companies
          offer{' '}
          <span style={{ color: '#01D2B2', fontWeight: 'bold' }}> DeFi </span>{' '}
          investments opportunities to their members.
          <br /> <br /> This website is only a{' '}
          <span style={{ fontStyle: 'italic' }}> showcase</span> for it.
        </Typography>
      </Box>
    </Container>
  );
}
