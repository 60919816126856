import React, { useEffect, useState, useContext } from 'react';
import { useAppContext } from '../context/State';
import { Container, Box, Typography, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../index.css';
import StatTitleBigBox from '../components/StatTitleBigBox';
import Listloader from '../components/loaders/ListLoader';
import { numberWithCommas } from '../helpers/numberWithCommas';
import TransactionsHistory from '../components/TransactionsHistory';
import TableRepartition from '../components/TableRepartition';
import ErrorBlock from '../components/ErrorBlock';
import DoughChart from '../components/DoughChart';
import { config } from '../helpers/authorizationHeader';

// interface ITablePools {
//   name: string;
//   protocol: string;
//   balanceOf: string;
//   stakedBalanceOf: string;
// }

// interface IDataTable {
//   [key: string]: {
//     value: number;
//     percentage: number;
//     pools: ITablePools[];
//   };
// }

export default function Walletpage() {
  const { address } = useAppContext();
  const [history, setHistory] = useState([]);
  const [unlabelled, setUnlabelled] = useState([]);
  const [debtValue, setDebtValue] = useState('0');
  const [expectedAPY, setExpectedAPY] = useState(0);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [loadingHistory, setLoadingHistory] = useState(true);
  const [loadingUnlabelled, setLoadingUnlabelled] = useState(true);
  const [loadingPositions, setLoadingPositions] = useState(true);
  const [loadingPie, setLoadingPie] = useState(true);
  const [positions, setPositions] = useState({});
  const [pieData, setPieData] = useState<any[]>([]);

  useEffect(() => {
    async function fetchDebtValue(walletAddress: string) {
      const url = process.env.REACT_APP_URL;
      const { data } = await axios.get(
        `${url}/v0/ethereum/debt-value-and-yield/${walletAddress}`,
        config
      );
      if (data.data) {
        setDebtValue(parseFloat(data.data.value).toFixed(2));
        setExpectedAPY(data.data.expected_apy / 100);
        setLoading(false);
      } else if (data.err) {
        setLoading(false);
        console.log(data.err);
        setError(data.err);
      }
    }

    async function fetchHistory(walletAddress: string) {
      const url = process.env.REACT_APP_URL;
      const { data } = await axios.get(
        `${url}/v0/ethereum/transactions/${walletAddress}`,
        config
      );
      if (data.data) {
        console.log(data.data);
        const labelledTx = data.data.filter((elem: any) => elem.usdValue !== 0);
        console.log(labelledTx);
        setHistory(labelledTx);
        setLoadingHistory(false);
        const unlabelledTx = data.data.filter(
          (elem: any) => elem.usdValue === 0
        );
        console.log(unlabelledTx);
        setUnlabelled(unlabelledTx);
        setLoadingUnlabelled(false);
      } else if (data.err) {
        console.log(data.err);
        setLoadingHistory(false);
        setLoadingUnlabelled(false);
        // setError(data.err);
      }
    }

    async function fetchPositions(walletAddress: string) {
      const url = process.env.REACT_APP_URL;
      const { data } = await axios.get(
        `${url}/v0/ethereum/positions/${walletAddress}/protocolview`,
        config
      );
      if (data.data) {
        console.log(data.data);
        const dataTable = data.data;
        setPositions(dataTable);
        setLoadingPositions(false);
        const pieDataY = [] as any[];
        await Promise.all(
          Object.entries(dataTable).map(async ([key, value]: Array<any>) => {
            pieDataY.push({
              id: key,
              label: key,
              value: value.value,
              color: '#1b3558',
            });
          })
        );
        setPieData(pieDataY);
        setLoadingPie(false);
      } else if (data.err) {
        console.log(data.err);
        setLoadingPositions(false);
        setLoadingPie(false);
        setError(data.err);
      }
    }

    if (address) {
      console.log('here');
      fetchDebtValue(address);
      fetchHistory(address);
      fetchPositions(address);
    }
  }, [address]);

  return (
    <Container className="main-container" maxWidth="xl">
      {error ? (
        <Box>
          <ErrorBlock message={error} />
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            mt: 1,
          }}
        >
          <Typography
            sx={{ fontSize: 28, fontWeight: 'bold', letterSpacing: 1 }}
          >
            Wallet Holdings - UNDER MAINTENANCE
          </Typography>
          {/* {address ? (
            <>
              <Grid
                container
                spacing={2}
                mt={2}
                sx={{
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <StatTitleBigBox
                  title={'Value'}
                  stat={'$' + numberWithCommas(debtValue)}
                  loading={loading}
                />
                <StatTitleBigBox
                  title={'Expected APY'}
                  stat={expectedAPY + '%'}
                  loading={loading}
                />
              </Grid>
              <Grid
                container
                spacing={2}
                mt={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <DoughChart pieData={pieData} loading={loadingPie} />
              </Grid>
              <TableRepartition
                positions={positions}
                loading={loadingPositions}
              />
              <TransactionsHistory
                title={'Transactions History'}
                history={history}
                loading={loadingHistory}
                color={'#FE8F96'}
              />
              {unlabelled.length > 0 ? (
                <TransactionsHistory
                  title={'Unlabeled Transactions'}
                  history={unlabelled}
                  loading={loadingUnlabelled}
                  color={'#EEB89E'}
                  bottom={true}
                />
              ) : null}
            </>
          ) : (
            <Listloader />
          )} */}
        </Box>
      )}
    </Container>
  );
}
