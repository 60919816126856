import { Typography, Box } from '@mui/material';
import { Oval as Gridtimer } from 'react-loader-spinner';

export default function Listloader() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        mt: '20vh',
      }}
    >
      <Gridtimer
        color="#FF968D"
        height="200"
        width="200"
        secondaryColor="#F8ECEA"
      />
    </Box>
  );
}
