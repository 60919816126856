import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import logo from '../img/logo.png';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../context/State';
import { useAutoConnect } from '../hooks/useAutoConnect';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useAccount, useSigner } from 'wagmi';
import '../index.css';

export default function Header() {
  const { address, setAddress, setContractSigner } = useAppContext();
  const navigate = useNavigate();
  const signer = useSigner();
  const wagmi = useAccount();
  useAutoConnect();

  const goHome = () => {
    navigate('/');
  };

  useEffect(() => {
    async function checkIfWalletIsConnected() {
      try {
        if (wagmi) {
          if (wagmi.address) {
            setAddress(wagmi.address);
            if (signer && signer.data) {
              setContractSigner(signer.data);
            }
          } else {
            setAddress(undefined);
          }
        } else {
          throw new Error('No WAGMI Provider');
        }
      } catch (error) {
        console.log(error);
      }
    }

    checkIfWalletIsConnected();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wagmi]);

  return (
    <Container
      maxWidth={false}
      sx={{
        width: '100%',
        backgroundColor: '#1D2023',
        display: 'flex',
        justifyContent: 'space-between',
        padding: 1.5,
      }}
    >
      <Box
        onClick={() => goHome()}
        sx={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginLeft: { xs: 1, sm: 5 },
          width: { xs: 120, sm: 150, md: 185 },
          height: { xs: 20, sm: 40, md: 50 },
        }}
      >
        <img src={logo} width={'100%'} height={'100%'} alt="logo" />
      </Box>
      <Container
        sx={{
          width: '100%',
          backgroundColor: '#1D2023',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        {
          // address !== undefined
          false ? (
            <>
              <Box>
                <Box
                  className="WalletButton"
                  sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    textAlign: 'center',
                    mr: 3,
                  }}
                  onClick={() => navigate('/wallet')}
                >
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: 15,
                      color: '#01D2B2',
                      border: 1,
                      padding: 1,
                      borderRadius: '5px',
                      borderColor: '#FFF',
                      ':hover': {
                        color: '#ECB1A4',
                      },
                    }}
                  >
                    Wallet Positions
                  </Typography>
                </Box>
              </Box>
            </>
          ) : null
        }
        <Box>
          <ConnectButton
            label="Sign in"
            accountStatus="address"
            showBalance={false}
            chainStatus="icon"
          />
        </Box>
      </Container>
    </Container>
  );
}
