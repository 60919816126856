import { Typography, Grid, Box } from '@mui/material';
import Smallloader from './loaders/SmallLoader';
import { Item } from './styledElements/Item';

export default function StatTitleBigBox(props: any) {
  return (
    <Grid item xs={4}>
      <Item
        sx={{
          height: 70,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography sx={{ fontSize: 12 }}> {props.title}</Typography>
        {!props.loading ? (
          <Typography sx={{ mt: 1, fontWeight: 'bold', fontSize: 24 }}>
            {props.stat}
          </Typography>
        ) : (
          <Box
            sx={{
              height: '40px',
              width: '40px',
            }}
          >
            <Smallloader />
          </Box>
        )}
      </Item>
    </Grid>
  );
}
