import React, { useEffect, useState, useContext } from 'react';
import {
  Container,
  Typography,
  Box,
  Button,
  Paper,
  InputBase,
} from '@mui/material';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import Smallloader from '../components/loaders/SmallLoader';
import axios from 'axios';
import { config } from '../helpers/authorizationHeader';

export default function AdminRequest() {
  const [urlToCheck, setUrlToCheck] = useState('');
  const [query, setQuery] = useState('');
  const [copied, setCopied] = useState(false);
  const [contentCopied, setContentCopied] = useState('');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const checkUrl = async () => {
    try {
      setLoading(true);
      const url = process.env.REACT_APP_URL;
      const { data } = await axios.post(
        `${url}/v0/internal/flatten`,
        { url: urlToCheck, query: query },
        config
      );
      const error = data.err;
      if (error) throw new Error(error.message);
      const result = data.data;
      if (result) {
        setData(result);
      } else {
        throw new Error('No result found. Contact the dev team.');
      }
      console.log(result);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const copyToClip = (elem: any) => {
    setContentCopied(elem);
    setCopied(true);
    navigator.clipboard.writeText(elem);
  };

  const testObj = {
    'tomistesting1.test': 1,
    'omistesting1.test': 10,
    'tomistesting3.test': 293,
    'tomistesting01.test': 1320,
    'tomistesting1.testigo': 22,
  };

  return (
    <Container className="main-container" maxWidth="xl">
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          mt: 10,
        }}
      >
        <Typography
          sx={{
            fontWeight: 'bold',
            fontSize: '25px',
            justifyContent: 'center',
            textJustify: 'auto',
          }}
        >
          The{' '}
          <span style={{ textDecoration: 'underline' }}>
            {' '}
            <span style={{ color: '#26CFB2' }}> Object</span>
            <span style={{ color: '#FF849C' }}> Key</span>
            <span style={{ color: '#01BFE0' }}> Analyser</span>
          </span>{' '}
        </Typography>
        <Box>
          <Box
            sx={{
              mt: 2,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Paper
              elevation={5}
              component="form"
              sx={{
                p: '10px 5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: 1,
                width: 350,
                mb: 1,
              }}
            >
              <InputBase
                autoFocus={true}
                sx={{ ml: 1, flex: 1 }}
                placeholder="URL to check"
                inputProps={{ 'aria-label': 'url to look' }}
                onChange={(e) => setUrlToCheck(e.target.value)}
                value={urlToCheck}
              />
            </Paper>
          </Box>
          <Box
            sx={{
              mt: 2,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Paper
              elevation={5}
              component="form"
              sx={{
                p: '10px 5px',
                border: 1,
                width: 500,
                padding: 10,
                mb: 1,
              }}
            >
              <InputBase
                sx={{ width: '100%' }}
                placeholder="Graphql query if any"
                inputProps={{ 'aria-label': 'Graphql query if any' }}
                onChange={(e) => setQuery(e.target.value)}
                value={query}
              />
            </Paper>
          </Box>
          <Button
            variant="contained"
            sx={{
              borderRadius: '25px',
              width: 400,
              height: 40,
              mt: 1,
              backgroundColor: '#26CFB2',
              fontsize: 22,
              fontWeight: 'bold',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: '#F5F5F5',
                color: '#26CFB2',
              },
            }}
            onClick={() => checkUrl()}
          >
            Check
          </Button>
        </Box>
        <Box
          sx={{
            mt: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {loading ? (
            <Smallloader />
          ) : data ? (
            <Box sx={{ width: 900 }}>
              <Typography
                sx={{
                  fontSize: 25,
                  fontWeight: 'bold',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                RESULT
              </Typography>
              {copied ? (
                <Typography
                  sx={{
                    fontSize: 18,
                    color: '#FF849C',
                    fontWeight: 'bold',
                    mt: 1,
                    mb: 3,
                    padding: 1,
                    borderRadius: 2,
                    border: 1,
                  }}
                >
                  "{contentCopied}" copied to clipboard!
                </Typography>
              ) : null}
              <Box
                sx={{
                  mb: 10,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    mt: 1,
                    mb: 2,
                  }}
                >
                  <Box>
                    <Typography>KEY</Typography>
                  </Box>
                  <Typography sx={{ ml: 2, mr: 2 }}>{`  :  `}</Typography>
                  <Typography>VALUE</Typography>
                </Box>
                {Object.entries(data).map(([key, value]: any) => {
                  return (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Box
                          sx={{ width: 25, cursor: 'pointer', mr: 1 }}
                          onClick={() => copyToClip(key)}
                        >
                          <ContentCopyOutlinedIcon sx={{ width: 20 }} />
                        </Box>
                        <Typography>{key}</Typography>
                      </Box>
                      <Typography sx={{ ml: 2, mr: 2 }}>{`  :  `}</Typography>
                      <Typography>{value}</Typography>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Container>
  );
}
