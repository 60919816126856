import '../index.css';
import ListLoader from './loaders/ListLoader';
import StatTitleBox from './StatTitleBox';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Button,
  Typography,
} from '@mui/material';
import { formatNumber } from '../helpers/formatNumber';

export default function PoolList(props: any) {
  return (
    <>
      {props.pools.length > 0 ? (
        <Grid
          className="poolGrid"
          container
          spacing={5}
          sx={{
            overflow: 'auto',
            maxHeight: { sm: '650px', md: '650px', xl: '800px' },
            width: { xs: 800, sm: '100%' },
            mb: 5,
          }}
        >
          {props.pools.map((pool: any, id: number) => (
            <Grid
              key={id}
              item
              xs={12}
              md={12}
              lg={12}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Card
                sx={{
                  width: { xs: '45%', sm: '100%' },
                  height: '100px',
                  borderRadius: '25px',
                  display: 'flex',
                  backgroundColor: '#1D1F23',
                  alignItems: 'center',
                }}
              >
                <CardContent
                  sx={{
                    width: '100%',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Box sx={{ width: '5%' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: 50,
                          width: { xs: 30, sm: 30, md: 30 },
                        }}
                      >
                        <img
                          src={
                            pool.chain.name === 'ethereum'
                              ? 'https://cryptologos.cc/logos/ethereum-eth-logo.png'
                              : pool.chain.name === 'bsc'
                              ? 'https://cryptologos.cc/logos/bnb-bnb-logo.png'
                              : pool.chain.name === 'arbitrum'
                              ? 'https://assets-global.website-files.com/5f973c970bea5548ad4287ef/60a320b472858ace6700df76_arb-icon.svg'
                              : pool.chain.name === 'optimism'
                              ? 'https://gateway.optimism.io/static/media/optimism.caeb9392.svg'
                              : pool.chain.name === 'polygon'
                              ? 'https://cryptologos.cc/logos/polygon-matic-logo.png'
                              : 'https://pngimg.com/uploads/question_mark/question_mark_PNG60.png'
                          }
                          style={{ width: '100%', borderRadius: 50 }}
                          alt="poolegy-logo"
                        />
                      </Box>
                    </Box>
                    <Box sx={{ width: '8%' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: 50,
                          width: { xs: 20, sm: 40, md: 50 },
                        }}
                      >
                        <img
                          src={pool.protocol.image}
                          style={{ width: '100%', borderRadius: 50 }}
                          alt="poolegy-logo"
                        />
                      </Box>
                    </Box>
                    <StatTitleBox
                      title={'Name'}
                      stat={pool.name ? pool.name : 'Test'}
                    />
                    <StatTitleBox
                      title={'Type'}
                      stat={pool.type || 'DeFi Pool'}
                    />
                    <StatTitleBox
                      title={'TVL'}
                      stat={
                        pool && pool.pool_analytics.length > 0
                          ? '$' +
                            formatNumber(
                              parseInt(String(pool.pool_analytics[0].tvl))
                            )
                          : '$' + formatNumber(0)
                      }
                    />
                    <StatTitleBox
                      title={'Activity APY'}
                      stat={
                        pool &&
                        pool.pool_analytics.length > 0 &&
                        pool.pool_analytics[0].activity_apy
                          ? formatNumber(
                              parseFloat(
                                String(pool.pool_analytics[0].activity_apy)
                              ).toFixed(3)
                            ) + '%'
                          : formatNumber(parseFloat('0').toFixed(3)) + '%'
                      }
                    />
                    <StatTitleBox
                      title={'Rewards APY'}
                      stat={
                        pool &&
                        pool.pool_analytics.length > 0 &&
                        pool.pool_analytics[0].rewards_apy
                          ? formatNumber(
                              parseFloat(
                                String(pool.pool_analytics[0].rewards_apy)
                              ).toFixed(3)
                            ) + '%'
                          : formatNumber(parseFloat('0').toFixed(3)) + '%'
                      }
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '15%',
                        mr: { xs: 3, sm: 2 },
                      }}
                    >
                      <Button
                        variant="contained"
                        sx={{
                          mt: 4,
                          borderRadius: '10px',
                          width: { xs: 50, sm: 80, md: 130 },
                          height: { xs: 30, sm: 50 },
                          backgroundColor: '#FE8F96',
                          fontSize: { xs: 10, sm: 12, md: 16 },
                          fontWeight: 'bold',
                          cursor: 'pointer',
                          '&:hover': {
                            backgroundColor: '#F5F5F5',
                            color: '#FE8F96',
                          },
                        }}
                        onClick={() =>
                          props.navigate(
                            `/dealpage/${pool.chain.name}/${pool.pool_address}`
                          )
                        }
                      >
                        More Info
                      </Button>
                      <>
                        <Typography sx={{ mt: 2, color: 'white', fontSize: 9 }}>
                          {pool && pool.pool_analytics[0].update_timestamp
                            ? 'Last Update: ' +
                              new Date(
                                parseInt(
                                  pool.pool_analytics[0].update_timestamp
                                ) * 1000
                              ).toLocaleTimeString() +
                              ' - ' +
                              new Date(
                                parseInt(
                                  pool.pool_analytics[0].update_timestamp
                                ) * 1000
                              ).toLocaleDateString()
                            : null}
                        </Typography>
                      </>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <ListLoader />
      )}
    </>
  );
}
