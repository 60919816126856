import { Typography, Box, Paper } from '@mui/material';

export default function RiskBox(props: any) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        mb: 4,
        width: '100%',
      }}
    >
      <Paper
        sx={{
          width: '80%',
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
        elevation={5}
      >
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'left',
            fontWeight: 'bold',
            mt: 1,
            ml: 3,
            color: props.color || 'black',
          }}
        >
          {props.title || 'Title'}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 2,
          }}
        >
          <Typography
            sx={{
              width: '95%',
              textAlign: 'left',
              fontSize: 14,
            }}
          >
            {' '}
            {props.text || 'text'}
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
}
