import { Grid, Typography, Box, Button, Paper, InputBase } from '@mui/material';
import { Item } from './styledElements/Item';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { numberWithCommas } from '../helpers/numberWithCommas';
import Smallloader from './loaders/SmallLoader';

export default function DealInvestBox(props: any) {
  return (
    <Grid item xs={5}>
      <Typography sx={{ ml: 1, fontWeight: 'bold', fontSize: 26 }}>
        Invest
      </Typography>
      <Item
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          height: props.height,
          mb: props.mb,
        }}
      >
        <Box>
          <Typography sx={{ fontSize: 18 }}>
            Current Position in this Pool
          </Typography>
          {props.positionLoading ? (
            <Smallloader />
          ) : (
            <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>
              {numberWithCommas(
                Math.floor(parseFloat(props.position) * 1000) / 1000
              )}{' '}
              pool tokens
            </Typography>
          )}
        </Box>
        {/* {props.noClaimable ? null : (
          <Box>
            <Typography sx={{ fontSize: 16, mt: 1 }}>
              Token claimable from this position
            </Typography>
            <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>
              {numberWithCommas(parseFloat(props.claimableRewards).toFixed(2))}
            </Typography>
          </Box>
        )} */}
        <Box sx={{ mt: 3 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Paper
              elevation={5}
              component="form"
              sx={{
                p: '10px 5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: 1,
                width: 350,
                mb: 1,
              }}
            >
              <InputBase
                autoFocus={true}
                sx={{ ml: 1, flex: 1 }}
                placeholder="$1,000"
                inputProps={{ 'aria-label': 'invest money' }}
                onChange={(e) => props.onInputValue(e.target.value)}
                value={props.inputValue}
              />
            </Paper>
          </Box>
          <>
            {props.loading ? (
              <Smallloader />
            ) : props.address ? (
              <>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <>
                    <Button
                      variant="contained"
                      sx={{
                        borderRadius: '25px',
                        width: 200,
                        height: 40,
                        mt: 1,
                        backgroundColor: '#FE859D',
                        fontsize: 14,
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        '&:hover': {
                          backgroundColor: '#F5F5F5',
                          color: '#FE859D',
                        },
                      }}
                      onClick={() => props.onInvestClick()}
                    >
                      Invest
                    </Button>
                  </>
                </Box>
                <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
                  <>
                    {props.dealAddress.toLowerCase() !==
                    String(
                      '0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84'
                    ).toLowerCase() ? (
                      <Button
                        variant="contained"
                        sx={{
                          borderRadius: '25px',
                          width: 200,
                          height: 40,
                          mt: 1,
                          // mr: 2,
                          backgroundColor: '#EDB69F',
                          fontsize: 14,
                          fontWeight: 'bold',
                          cursor: 'pointer',
                          '&:hover': {
                            backgroundColor: '#F5F5F5',
                            color: '#EDB69F',
                          },
                        }}
                        onClick={() => props.onRedeemClick()}
                      >
                        Redeem
                      </Button>
                    ) : null}
                    {/* <Button
                      variant="contained"
                      sx={{
                        borderRadius: '25px',
                        width: 200,
                        height: 40,
                        mt: 1,
                        backgroundColor: '#EDB69F',
                        fontsize: 14,
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        '&:hover': {
                          backgroundColor: '#F5F5F5',
                          color: '#EDB69F',
                        },
                      }}
                      onClick={() => props.onRedeemAndClaimClick()}
                    >
                      Redeem & Claim
                    </Button> */}
                  </>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <>
                    <Button
                      variant="contained"
                      sx={{
                        borderRadius: '25px',
                        width: 200,
                        height: 40,
                        mt: 1,
                        backgroundColor: '#FE859D',
                        fontsize: 14,
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        '&:hover': {
                          backgroundColor: '#F5F5F5',
                          color: '#FE859D',
                        },
                      }}
                      onClick={() => props.onStakeClick()}
                    >
                      Stake
                    </Button>
                  </>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <>
                    <Button
                      variant="contained"
                      sx={{
                        borderRadius: '25px',
                        width: 200,
                        height: 40,
                        mt: 1,
                        backgroundColor: '#EDB69F',
                        fontsize: 14,
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        '&:hover': {
                          backgroundColor: '#F5F5F5',
                          color: '#EDB69F',
                        },
                      }}
                      onClick={() => props.onUnstakeClick()}
                    >
                      Unstake
                    </Button>
                  </>
                </Box>
                {/* <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
                  <>
                    <Button
                      variant="contained"
                      sx={{
                        borderRadius: '25px',
                        width: 200,
                        height: 40,
                        mt: 1,
                        mr: 2,
                        backgroundColor: '#FE859D',
                        fontsize: 14,
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        '&:hover': {
                          backgroundColor: '#F5F5F5',
                          color: '#FE859D',
                        },
                      }}
                      onClick={() => props.onClaimClick()}
                    >
                      Claim
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        borderRadius: '25px',
                        width: 200,
                        height: 40,
                        mt: 1,
                        backgroundColor: '#FE859D',
                        fontSize: 14,
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        '&:hover': {
                          backgroundColor: '#F5F5F5',
                          color: '#FE859D',
                        },
                      }}
                      onClick={() => props.onClaimAndSellClick()}
                    >
                      Claim & Sell
                    </Button>
                  </>
                </Box> */}
              </>
            ) : (
              <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                <ConnectButton label="Sign in to invest" />
              </Box>
            )}
          </>
        </Box>

        <Box sx={{ mt: 5 }}>
          <Typography sx={{ fontSize: 18 }}> Swap Money </Typography>
          <Paper
            elevation={5}
            component="form"
            sx={{
              p: '10px 5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: 1,
              width: 350,
              mb: 1,
            }}
          >
            <InputBase
              autoFocus={true}
              sx={{ ml: 1, flex: 1 }}
              placeholder="From Token (0x...123)"
              inputProps={{ 'aria-label': 'From Token' }}
              onChange={(e) => props.onFromInputValue(e.target.value)}
              value={props.fromToken}
            />
          </Paper>
          <Paper
            elevation={5}
            component="form"
            sx={{
              p: '10px 5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: 1,
              width: 350,
              mb: 1,
            }}
          >
            <InputBase
              autoFocus={true}
              sx={{ ml: 1, flex: 1 }}
              placeholder="To Token (0x...789)"
              inputProps={{ 'aria-label': 'To Token' }}
              onChange={(e) => props.onToInputValue(e.target.value)}
              value={props.toToken}
            />
          </Paper>
          <Paper
            elevation={5}
            component="form"
            sx={{
              p: '10px 5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: 1,
              width: 350,
              mb: 1,
            }}
          >
            <InputBase
              autoFocus={true}
              sx={{ ml: 1, flex: 1 }}
              placeholder="Amount to Swap ($200)"
              inputProps={{ 'aria-label': 'Amount to Swap' }}
              onChange={(e) => props.onSwapInputValue(e.target.value)}
              value={props.amountToSwap}
            />
          </Paper>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            {props.loading ? (
              <Smallloader />
            ) : props.address ? (
              <>
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: '25px',
                    width: 200,
                    height: 40,
                    mt: 1,
                    backgroundColor: '#FE859D',
                    fontsize: 14,
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: '#F5F5F5',
                      color: '#FE859D',
                    },
                  }}
                  onClick={() => props.onSwapClick()}
                >
                  Swap
                </Button>
              </>
            ) : (
              <Box sx={{ mt: 2 }}>
                <ConnectButton label="Sign in to swap" />
              </Box>
            )}
          </Box>
        </Box>
      </Item>
    </Grid>
  );
}
