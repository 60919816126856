import { createContext, useState, useContext } from 'react';

const AppContext = createContext<any | undefined>(undefined);

export function useAppContext() {
  return useContext(AppContext);
}

export default function ContextProvider(props: any) {
  const [address, setAddress] = useState<string | undefined>(undefined);
  const [contractSigner, setContractSigner] = useState<any | undefined>(
    undefined
  );
  const [switchNetwork, setSwitchNetwork] = useState<any | undefined>(
    undefined
  );
  const [chains, setChains] = useState<any | undefined>(undefined);
  const [chain, setChain] = useState<any | undefined>(undefined);

  return (
    <AppContext.Provider
      value={{
        address,
        setAddress,
        contractSigner,
        setContractSigner,
        switchNetwork,
        setSwitchNetwork,
        chain,
        setChain,
        chains,
        setChains,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
}
