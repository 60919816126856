import React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

const General = () => {
  return (
    <Box>
      <form>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Typography
              variant={'subtitle2'}
              sx={{ marginBottom: 2 }}
              fontWeight={700}
            >
              Protocol name
            </Typography>
            <TextField
              label="Protocol *"
              variant="outlined"
              name={'fullName'}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              variant={'subtitle2'}
              sx={{ marginBottom: 2 }}
              fontWeight={700}
            >
              Protocol Category
            </Typography>
            <TextField
              label="Category *"
              variant="outlined"
              name={'email'}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant={'subtitle2'}
              sx={{ marginBottom: 2 }}
              fontWeight={700}
            >
              Description
            </Typography>
            <TextField
              label="Description"
              variant="outlined"
              name={'bio'}
              multiline
              rows={5}
              fullWidth
            />
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default General;
