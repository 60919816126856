import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '../components/Container';
import FormInfo from '../components/Connector/General/Form';
import AddPool from '../components/Connector/Pools/Pool';

const steps = [
  {
    label: 'Select Protocol',
    description: `Give the name and the information about the protocols`,
  },
  {
    label: 'Define Pools',
    description:
      'An ad group contains one or more ads which target a shared set of keywords.',
  },
  {
    label: 'Analytics Information',
    description: `Try out different ad text to see what brings in the most customers,
              and learn how to enhance your ads using features like ad extensions.
              If you run into any problems with your ads, find out how to tell if
              they're running and how to resolve approval issues.`,
  },
  {
    label: 'Interaction Information',
    description: `Sed ut perspiciatis unde omnis iste natus error sit
              voluptatem accusantium doloremque laudantium, totam rem aperiam,
              eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.`,
  },
  {
    label: 'Position Information',
    description: `Sed ut perspiciatis unde omnis iste natus error sit
              voluptatem accusantium doloremque laudantium, totam rem aperiam,
              eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.`,
  },
];

const Connector = () => {
  const theme = useTheme();
  console.log(theme);
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;

    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  return (
    <Container>
      <Box width={1}>
        <Stepper activeStep={activeStep}>
          {steps.map((step, index) => (
            <Step
              key={step.label}
              completed={completed[index]}
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding: 0,
                width: '100%',
                '& .MuiButtonBase-root': {
                  position: 'relative',
                  bgcolor:
                    activeStep === index ? 'primary.main' : 'alternate.main',
                  color: activeStep === index ? 'text.primary' : 'common.white',
                  height: theme.spacing(6),
                  padding: theme.spacing(0, 3),
                  zIndex: 1,
                },
                '& .MuiStepLabel-label.Mui-active': {
                  color: theme.palette.common.white,
                },
                '& .MuiSvgIcon-root.Mui-active': {
                  color: theme.palette.common.white,
                  '& .MuiStepIcon-text': {
                    fill: theme.palette.primary.main,
                  },
                },
              }}
            >
              <React.Fragment>
                <StepButton onClick={handleStep(index)}>
                  {isMd ? step.label : ''}
                </StepButton>
                {index === steps.length - 1 ? null : (
                  <Box
                    sx={{
                      width: 0,
                      height: 0,
                      borderTop: `${theme.spacing(3)} solid transparent`,
                      borderBottom: `${theme.spacing(3)} solid transparent`,
                      borderLeft: `${theme.spacing(2)} solid ${
                        activeStep === index
                          ? theme.palette.primary.main
                          : theme.palette.secondary.main
                      }`,
                      transform: `translateX(${theme.spacing(0)})`,
                      zIndex: 2,
                    }}
                  />
                )}
              </React.Fragment>
            </Step>
          ))}
        </Stepper>
        <div>
          {allStepsCompleted() ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button
                  color="primary"
                  variant={'outlined'}
                  onClick={handleReset}
                >
                  Reset
                </Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {activeStep === 0 ? (
                <Box sx={{ mt: 2, mb: 1, p: 2 }}>
                  <FormInfo
                    title={steps[activeStep].label}
                    description={steps[activeStep].description}
                  />
                </Box>
              ) : null}
              {activeStep === 1 ? (
                <Box sx={{ mt: 2, mb: 1, p: 2 }}>
                  <AddPool />
                </Box>
              ) : null}
              {activeStep === 2 ? (
                <Box sx={{ mt: 2, mb: 1, p: 2 }}>
                  <AddPool />
                </Box>
              ) : null}
              {activeStep === 3 ? (
                <Box sx={{ mt: 2, mb: 1, p: 2 }}>
                  <AddPool />
                </Box>
              ) : null}
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                  color="primary"
                  variant={'outlined'}
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button
                  variant={'contained'}
                  onClick={handleNext}
                  sx={{ mr: 1 }}
                >
                  Next
                </Button>
                {activeStep !== steps.length && (
                  <Button onClick={handleComplete} sx={{ fontWeight: 700 }}>
                    {completedSteps() === totalSteps() - 1
                      ? 'Finish'
                      : 'Complete step'}
                  </Button>
                )}
              </Box>
            </React.Fragment>
          )}
        </div>
      </Box>
    </Container>
  );
};

export default Connector;
