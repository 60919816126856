import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

export default function Footer() {
  //   const { address, setAddress } = useAppContext();

  return (
    <Container
      maxWidth={false}
      sx={{
        mt: 5,
        width: '100%',
        backgroundColor: '#1D2023',
        display: 'flex',
        justifyContent: 'flex-end',
        padding: 0.8,
        position: 'fixed',
        bottom: 0,
      }}
    >
      <>
        <a
          style={{ textDecoration: 'none' }}
          href="https://valha.readme.io/reference/introduction"
          target="_blank"
          rel="noreferrer"
        >
          <Box
            sx={{
              display: {
                xs: 'none',
                md: 'flex',
              },
            }}
          >
            <Box sx={{ cursor: 'pointer' }}>
              <Typography
                sx={{
                  cursor: 'pointer',
                  fontWeight: 'bold',
                  fontSize: 11,
                  color: '#F5F5F5',
                  ':hover': {
                    color: '#01D2B2',
                  },
                }}
              >
                API Doc
              </Typography>
            </Box>{' '}
          </Box>
        </a>
        <Box
          sx={{
            display: {
              xs: 'none',
              md: 'flex',
            },
            color: '#F87060',
            cursor: 'pointer',
            ml: 5,
          }}
        >
          <a
            style={{ textDecoration: 'none' }}
            href="https://dune.com/octavionotpunk/debt-dashboard"
            target="_blank"
            rel="noreferrer"
          >
            <Box>
              <Typography
                sx={{
                  fontSize: 11,
                  cursor: 'pointer',
                  fontWeight: 'bold',
                  color: '#F5F5F5',
                  ':hover': {
                    color: '#01D2B2',
                  },
                }}
              >
                Analytics Dashboard
              </Typography>
            </Box>
          </a>
        </Box>
        <Box
          sx={{
            display: {
              xs: 'none',
              md: 'flex',
            },
            color: '#01D2B2',
            cursor: 'pointer',
            ml: 5,
          }}
        >
          <a
            style={{ textDecoration: 'none' }}
            href="https://twitter.com/OctavioNotPunk"
            target="_blank"
            rel="noreferrer"
          >
            <Box>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  cursor: 'pointer',
                  color: '#F5F5F5',
                  fontSize: 11,
                  ':hover': {
                    color: '#01D2B2',
                  },
                }}
              >
                Contact
              </Typography>
            </Box>
          </a>
        </Box>
      </>
    </Container>
  );
}
